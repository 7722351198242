import axios from '@utils/services'

export const fetchBalance = async () => {
    try {
      const response = await axios.get('/api/DonApp/Balance');
      return response.data.data;
    } catch (err) {
      console.log(err.response);
    }
  };

export default { fetchBalance }