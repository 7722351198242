import { CurrencyFormat, DateTimeFormat } from "@utils";
export const columns = [
  { selector: (row) => row.upc, name: "UPC", sort: true },
  { selector: (row) => CurrencyFormat(row.amount), name: "Monto", sort: true },
  { selector: (row) => row.branchNumber, name: "Banch Number", sort: true },
  { selector: (row) => row.input, name: "Input", sort: true },
  { selector: (row) => row.errorMessage, name: "Mensaje", sort: true },
  { selector: (row) => row.product, name: "Producto", sort: true },
  {
    selector: (row) => DateTimeFormat(row.createdDateTime),
    name: "Fecha",
    sort: true,
  },
  { selector: (row) => row.providerErrorCode, name: "Error Code", sort: true },
];

export default { columns };
