import React, { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { fetchBalance } from "@services/balance";
import { CurrencyFormat } from "@utils";
import "./balance.scss";

const Balance = () => {
  const { data: currentBalance, refetch } = useQuery("balance", fetchBalance);
  const handleUpdate = () => {
    refetch();
  };
  useEffect(() => {
    currentBalance ?? handleUpdate();
  }, []);
  return (
    <div className="container containerStyle">
      <h1 className="titleHome">Balance</h1>
      <h4 className="hYellow">
        {currentBalance && <div>{CurrencyFormat(currentBalance.tae)}</div>}
      </h4>
      <h6 className="hYellow">TAE</h6>
      <br />
      <h4 className="hGreen">
        {currentBalance && <div>{CurrencyFormat(currentBalance.services)}</div>}
      </h4>
      <h6 className="hGreen">Servicios</h6>
      <br />
    </div>
  );
};

export default Balance;
