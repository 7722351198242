import React, { useEffect } from "react";
import { Header, RouteDay } from "@components";
import { fetchPickups } from "@services/picklup";
import { connect } from "react-redux";
import { useQuery } from "react-query";

const CollectRoutes = ({ auth, ...props }) => {
  const { data: pickups, refetch,isFetching } = useQuery(
    ["Pickups", auth],
    () => fetchPickups(auth)
  );
  useEffect(() => {
    pickups ?? refetch();
  }, []);
  return (
    <>
      <Header />
      <div className="container containerStyle ">
        <h1 className="viewTitle ">Recolecciones del día</h1>
        {pickups && auth.user.role == "Pickup" && (
          <RouteDay
            Pickup={pickups.find((f) => f.pickupNumber == auth.branchID)}
          />
        )}
        {pickups &&
          auth.user.role == "Admin" &&
          pickups.map((item) => {
            return <RouteDay Pickup={item} key={item.pickupNumber} />;
          })}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};
export default connect(mapStateToProps, null)(CollectRoutes);
