import React, { useEffect } from "react";
import { Header, DueAccounts } from "@components";
import { fetchPickups } from "@services/picklup";
import { connect } from "react-redux";
import { useQuery } from "react-query";

const Due = ({ auth, ...props }) => {
  const { data: pickups, refetch,isFetching } = useQuery(
    ["Pickups", auth],
    () => fetchPickups(auth)
  );
  useEffect(() => {
    pickups ?? refetch();
  }, []);
  return (
    <>
      <Header />
      <div className="container containerStyle ">
        <h1 className="viewTitle ">Tiendas con saldo Vencido</h1>
        {auth.user && (
          <DueAccounts />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};
export default connect(mapStateToProps, null)(Due);
