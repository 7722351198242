import React from 'react'
import {BrowserRouter,Routes,Route } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux/store'
import { Spinner, PrivateRoute, Footer } from '@components'
import {Home,LogIn} from "@pages"
import { LOGIN, HOME,REGISTER,PICKUPTABLE,FAILEDTX,ROUTEDAY,DUE} from '@constants'
import './App.scss'
import ErrorPage from './components/ErrorPage/ErrorPage';
import RegisterClients from './pages/RegisterClients';
import Due from './pages/Due';
import  PickupTable  from './pages/PickupTable';
import  CollectRoutes  from './pages/CollectRoutes';
import FailedTX from './pages/FailedTX';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'


const App = () => {
  const queryClient = new QueryClient({defaultOptions:{ queries:{ refetchInterval : 300000, refetchOnWindowFocus : false } }});
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <div id="page-container">
          <div id="content-wrap">
            <Spinner />
            <BrowserRouter>
              <Routes>
                <Route path={LOGIN}         element={  <LogIn />} exact />
                <Route path={HOME}          element={  <PrivateRoute > <Home /> </PrivateRoute>} />
                <Route path={REGISTER}      element={  <PrivateRoute role={"Admin"}> <RegisterClients /> </PrivateRoute>} />
                <Route path={DUE}           element={  <PrivateRoute role={"Pickup"}> <Due /> </PrivateRoute>} />
                <Route path={PICKUPTABLE}   element={  <PrivateRoute role={"Admin"}> <PickupTable /> </PrivateRoute>} />
                <Route path={FAILEDTX}      element={  <PrivateRoute role={"Admin"}> <FailedTX /> </PrivateRoute>} />
                <Route path={ROUTEDAY}      element={  <PrivateRoute role={"Pickup"}> <CollectRoutes /> </PrivateRoute>} />
                <Route path="*"             element={  <ErrorPage/>} />
                {/* <PrivateRoute path={DONAPP} element={<DonApp />} exact /> */}
              </Routes>
            </BrowserRouter>
          </div>
          {/* <Footer /> */}
        </div>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
