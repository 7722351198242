import React from "react";
import { connect } from "react-redux";

function Authorizor({ children, user, ...props }) {
  if (props.authLevel > user.authLevel) {
    return null;
  }
  const { ComponentToValidate } = props;
  return <ComponentToValidate {...props} />;
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};
export default connect(mapStateToProps, null)(Authorizor);
