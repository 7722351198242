import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { required } from "@utils";
import "./formModal.scss";
import { registredDeposit } from "@services/picklup";
import { useMutation, useQueryClient } from "react-query";
import { depositar } from "@assets";
import Swal from "sweetalert2";
export const FormModal = ({ token, changeState }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(registredDeposit, {
    onSuccess: (response) => {
      if (response.succeeded) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deposito registrado con exito",
          showConfirmButton: true,
          text: "Deposito registrado con exito",
        }).then((result) => {
          queryClient.invalidateQueries("pickups");
          changeState(false);
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Error",
          showConfirmButton: true,
          text: response.message,
        });
      }
    },
  });

  const handleConsole = async (values) => {
    values.Amount = parseFloat(values.Amount);
    values.Pickup = token;
    const formData = new FormData();
    var imagefile = document.querySelector("#File");
    formData.append("File", imagefile.files[0]);
    for (var key in values) {
      formData.append(key, values[key]);
    }
    mutation.mutate(formData);
  };
  const defaultValues = {
    BankName: "BBVA",
    Token: token,
    BankAccount: "TAE",
  };

  return (
    <>
      <div>
        <Form
          onSubmit={handleConsole}
          initialValues={defaultValues}
          validate={(values) => {
            const errors = {};
            if (!values.Amount) {
              errors.Amount = "Required";
            }
            if (!values.AmountConfirm) {
              errors.AmountConfirm = "Required";
            } else if (values.Amount !== values.AmountConfirm) {
              errors.AmountConfirm = "El monto y su confirmación no coinciden";
            }
            return errors;
          }}
          render={({
            handleSubmit,
            form,
            pristine,
            submitting,
            valid,
            values,
          }) => (
            <form className="container-form-pickup" onSubmit={handleSubmit}>
              <div className="row">
                <Field name="Amount" component="input" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="labelForm" htmlFor="Amount">
                        Monto:
                      </label>
                      <input className="inputForm" type="number" {...input} />
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>

                <br />
                <Field
                  name="AmountConfirm"
                  component="input"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="labelForm" htmlFor="AmountConfirm">
                        Confirma monto:
                      </label>
                      <input className="inputForm" type="number" {...input} />
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>

                <br />
                <Field name="BankName" component="input" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="labelForm" htmlFor="BankName">
                        Banco:{" "}
                      </label>
                      <input
                        className="inputForm"
                        type="text"
                        id="BankName"
                        initialvalue="BBVA"
                        {...input}
                      />
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <br />

                <label className="labelForm" htmlFor="BankAccount">
                  Cuenta a la que se realiza el depósito :
                </label>
                <Field
                  className="inputFormSelect"
                  name="BankAccount"
                  component="select"
                  validate={required}
                >
                  <option value="">Seleccione una opción</option>
                  <option value="012580001183104916">
                    Concentradora - 0118310491
                  </option>
                </Field>
                <br />

                <Field name="BankAuth" component="input" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="labelForm" htmlFor="BankAuth">
                        # de Autorización:
                      </label>
                      <input
                        className="inputForm"
                        type="text"
                        id="BankAuth"
                        {...input}
                      />
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <br />
                <Field name="File" component="file">
                  {({ input, meta }) => (
                    <>
                      <label className="labelForm" htmlFor="BankName">
                        Imagen de depósito:{" "}
                      </label>
                      <input
                        className="form-control inputFormImg"
                        type="file"
                        id="File"
                        {...input}
                      />
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <br />
                <button className="enviar">
                  <div class="reg-wrapper-1">
                    <div class="reg-wrapper">
                      <img
                        className="reg"
                        src={depositar}
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      ></img>
                    </div>
                  </div>
                  <span> Depositar</span>
                </button>
              </div>
            </form>
          )}
        />
      </div>
    </>
  );
};
