import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { fetchResume } from "@services/resume";
import { CurrencyFormat } from "@utils";
import "./salesResume.scss";

const SalesResume = () => {
  const { data: salesResume, refetch } = useQuery("salesResume", fetchResume);
  const handleUpdate = () => {
    refetch();
  };
  useEffect(() => {
    salesResume ?? handleUpdate();
  }, []);
  return (
    <>
      <div id="container-salesResume" className="container containerSalesResume">
        <h1 className="titleHome">Resumen de ventas</h1>

        <div className="content-resume">
          <div className="box-sales-resume">
            <h5 className="h5Resume">Total:</h5>
            <h6>
              {salesResume && (
                <span className="spanResume">
                  {CurrencyFormat(salesResume.total)}
                </span>
              )}
            </h6>
          </div>
          <div className=" box-sales-resume">
            <h5 className="h5Resume">Don App:</h5>
            <h6>
              {salesResume && (
                <span className="spanResume">
                  {CurrencyFormat(salesResume.donAppFees)}
                </span>
              )}
            </h6>
          </div>
          <div className=" box-sales-resume">
            <h5 className="h5Resume">Rentabilidad:</h5>
            <h6>
              {salesResume && (
                <span className="spanResume">
                  {CurrencyFormat(
                    (salesResume.donAppFees / salesResume.total) * 100
                  )}{" "}
                  %
                </span>
              )}
            </h6>
          </div>
          <div className=" box-sales-resume">
            <h5 className="h5Resume">Store Fees:</h5>
            <h6>
              {salesResume && (
                <span className="spanResume">
                  {CurrencyFormat(salesResume.storeFees)}
                </span>
              )}
            </h6>
          </div>

          <div className=" box-sales-resume">
            <h5 className="h5Resume">Total de Transacciones:</h5>
            <h6>
              {salesResume && (
                <span className="spanResume">
                  {salesResume.totalOperations}
                </span>
              )}
            </h6>
          </div>
          {salesResume &&
            Object.keys(salesResume.salesByService).map(function (key) {
              return (
                <div className="box-sales-resume" key={key}>
                  {salesResume && (
                    <h5 className="h5Resume">
                      {salesResume.salesByService[key].service}:
                    </h5>
                  )}
                  <span className="spanResume">
                    {CurrencyFormat(salesResume.salesByService[key].amount)}
                  </span>
                </div>
              );
            })}
        </div>
        <br />
      </div>
    </>
  );
};
export default SalesResume;
