import { CurrencyFormat, PercentFormat,DateFormat,getDayOfWeekName } from "@utils"
export const statusOptions = [
    { value: 'Todos', label: 'Todos' },
    { value: 'Active', label: 'Active' },
    { value: 'Due', label: 'Due' },
    { value: 'OverDue', label: 'OverDue' },
    { value: 'Lost', label: 'Lost' },
 ]

 export const rankingOptions = [
    { value: 'Todos', label: 'Todos' },
    { value: 'Bad', label: 'Bad' },
    { value: 'Average', label: 'Average' },
    { value: 'God', label: 'God' },
    { value: 'Excellent', label: 'Excellent' },
 ]
 export const columns = [
    { selector: row => row.branchNumber, name: "Numero Tienda", sortable: false, wrap: true},
    { selector: row => row.branchName, name: "Branch Name", sortable: false, wrap: true},
    { selector: row => PercentFormat(row.profitability), name: "Rentabilidad", sortable: true, wrap: true},
    { selector: row => row.toRecover, name: "Total a Recuperar", sortable: true, wrap: true},
    { selector: row => DateFormat(row.lastRecolection), name: "Ultima Recolección", sortable: false, wrap: true},
    { selector: row => row.daysSinceLastRecolecction, name: "Dias", sortable: true, wrap: true},
    { selector: row => row.paymentRatio, name: "Ratio", sortable: true, wrap: true},
    { selector: row => getDayOfWeekName(row.recollectionDay), name: "Ruta", sortable: true, wrap: true},
    { selector: row => row.salesLimit, name: "Limite de Venta", sortable: true, wrap: true},
    { selector: row => row.branchID, name: "branchID", sortable: false, wrap: true,omit: true},
    { selector: row => row.userName, name: "Username", sortable: false, wrap: true,omit: true},
    { selector: row => row.adress, name: "Direccion", sortable: false, wrap: true,omit: true},
  ];
    
 export const columns2 = [
    { dataField: "branchNumber", text: "Branch Number", sort: true },
    { dataField: "branchName", text: "Branch Name", sort: true },
    { dataField: "profitability",text: "Rentabilidad",sort: true,formatter: PercentFormat,},
    { dataField: "toRecover",text: "Total a Recuperar",sort: true, formatter: CurrencyFormat,},
    { dataField: "lastRecolection",text: "Ultima Recolección",sort: true,formatter: DateFormat,},
    { dataField: "daysSinceLastRecolecction", text: "Dias", sort: true },
    { dataField: "paymentRatio",text: "Ratio",sort: true,},
    { dataField: "salesLimit",text: "Limite de Venta",sort: true,},
    { dataField: "branchID", text: "BranchID", sort: false, hidden : true },
    { dataField: "userName", text: "Username", sort: false, hidden : true },
    { dataField: "adress", text: "Direccion", sort: false, hidden : true },
  ];
export const defaultRanking = [
    { value: ['Bad','Due','God','Excellent'], label: 'Todos' }
]
export const defaultStatus = [
    { value: ['Active','Average','OverDue','Lost'], label: 'Todos' }
]
 
export default { statusOptions,rankingOptions,columns,defaultRanking,defaultStatus }