import React, { useEffect } from "react";
import { connect } from "react-redux";
import Lottie from "lottie-web";
import "./spinner.scss";
import animationData from "./loader";
import { useIsFetching, useIsMutating } from "react-query";

const Spinner = ({ loading }) => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#loader"),
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });
    Lottie.setSpeed(2);
  }, [loading || isFetching || isMutating]);
  return (
    <>
      {(loading || isFetching || isMutating) && (
        <div className="spinner">
          <div
            id="loader"
            style={{
              transform: "translate3d(0, 25%, 0)",
              height: "80%",
              width: "50%",
              margin: "auto",
              overflow: "hidden",
            }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return { loading: loading.loading };
};

export default connect(mapStateToProps, null)(Spinner);
