import React, { useEffect, useState, useMemo } from "react";
import Modal from 'react-modal';
import { connect } from "react-redux";
import { getBranchBalance,disableFeesToBranch,loading } from "@redux/actions/branchBalance";
import { CurrencyFormat, pageOptions, noDataComponent } from "@utils";
import { phone, location, adress, usericon, tienda } from "@assets";
import { columns } from "@tables/branches";
import DataTable from "react-data-table-component";
import { InputAdornment, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { COLORS } from "../../styles/colors";
import "./tableBranchBalance.scss";
import "./balance.scss";
import Swal from "sweetalert2";
import { doDepositToBranch,getDonToken } from '@services/branchBalance';

const BranchBalance = ({ branches, fetchBranchBalance, loading }) => {
  const [filterText, setFilterText] = React.useState("");
  const [data, setData] = useState(["Active", "Average", "OverDue", "Lost"]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [filteredItems, setfilteredItems] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    branchID: '',
    amount: '',
    bankAuthNumber: ''
  });
  const [isGenerateTokenModalOpen, setIsGenerateTokenModalOpen] = useState(false);
  const [collectFormData, setCollectFormData] = useState({
    branchID: '',
    inTransit: '',
    collect: ''
  });
  
  useEffect(() => {
    if (data)
      setfilteredItems(
        data.filter((o) => {
          return ((o.branchID && o.branchID.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.branchName && o.branchName.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.recollectionDay && o.recollectionDay.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.branchNumber && o.branchNumber.toString().indexOf(filterText) !== -1) ||
            (o.userName && o.userName.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.adress && o.adress.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.status && o.status.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.ranking && o.ranking.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
          );
        })
      );
  }, [filterText, data]);

  useEffect(() => {
    branches ?? fetchBranchBalance();
    if (branches) {
      setData(branches.data);
    }
  }, [branches]);

  const customStyles = {
    headCells: {
      style: {
        color: COLORS.white,
        fontWeight: 600,
        paddingRight: 0,
        fontSize: 14,
        backgroundColor: " #6533ff",
      },
    },
    table: {
      style: {
        maxHeight: "400px !important",
        overflow: "auto",
      },
    },
    headRow: {
      style: {
        zIndex: "0 !important",
      },
    },
    subHeader: {
      style: {
        padding: "0 10",
        marginBottom: "30px ",
        justifyContent: "space-between ",
      },
    },
    rows: {
      style: {
        fontSize: 14,
      },
    },
  };

  const openModal = (branchID) => {
    setIsModalOpen(true);
    setFormData({
      branchID,
      amount: '',
      bankAuthNumber: ''
    });
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openGenerateTokenModal = (inTransit,branchID) => {
    setIsGenerateTokenModalOpen(true);
    setCollectFormData({
      branchID,
      inTransit,
      collect: 0
    });
  };
  
  const closeGenerateTokenModal = () => {
    setIsGenerateTokenModalOpen(false);
  };
  const handleInputChangeDeposit = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = async () => {
    const payload = {
      BranchID: formData.branchID, // Utiliza el branchID del estado
      Amount: parseFloat(formData.amount),
      BankAuthNumber: formData.bankAuthNumber
    };
    console.log(payload);
    loading();
    //var response = {succeeded : true}
    var response = await doDepositToBranch(payload);
    loading();
    if(response.succeeded)
    {
      Swal.fire({
        position: "center",
        icon: "success",
        title: '<strong>Deposito</strong>',
        icon: 'success',
        html: `<p>Deposito registrado correctamente!</p>`
      })
    }
    else{
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Error",
        showConfirmButton: false,
        timer: 1500,
        text: "Ups, ocurrio un error",
      });
    }
    closeModal();
  };

  const handleGenerateTokenSubmit = async () => {
    loading();
    const payload = {
      BranchID: collectFormData.branchID,
      InTransit: parseFloat(collectFormData.inTransit),
      Collect: parseFloat(collectFormData.collect)
    };
    var response = await getDonToken(payload);
    loading();
    if(response.succeeded)
    {
      Swal.fire({
        position: "center",
        icon: "success",
        title: '<strong>Token</strong>',
        icon: 'success',
        html: `<p>${response.data.token}</p>`
      })
    }
    closeGenerateTokenModal();
  };
  
  const modalDeptoContent = (
    <div>
      <h2>Realizar Depósito</h2>
      <form className="form">
        <div>
          <label htmlFor="amount">Amount:</label>
          <input
            type="number"
            id="amount"
            name="amount"
            value={formData.amount}
            onChange={handleInputChangeDeposit}
          />
        </div>
        <div>
          <label htmlFor="bankAuthNumber">BankAuthNumber:</label>
          <input
            type="text"
            id="bankAuthNumber"
            name="bankAuthNumber"
            value={formData.bankAuthNumber}
            onChange={handleInputChangeDeposit}
          />
        </div>
        <button type="button" onClick={handleFormSubmit}>Submit</button>
      </form>
    </div>
  );
  const generateTokenModalContent = (
    <div>
      <h2>Generar Token</h2>
      <form className="form">
        <div>
          <label>InTransit:</label>
          <input
            type="text"
            value={collectFormData.inTransit}
            readOnly
          />
        </div>
        <div>
          <label>Collect:</label>
          <input
            type="text"
            name="collect"
            value={collectFormData.collect}
            onChange={(e) =>
              setCollectFormData({
                ...collectFormData,
                [e.target.name]: e.target.value
              })
            }
          />
        </div>
        <button type="button" onClick={handleGenerateTokenSubmit}>
          Generar Token
        </button>
      </form>
    </div>
  );
  
  const searchBar = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className="subHeaderStyle">
          <h1 className="titleHome">Tiendas Activas</h1>
          <h5 className="saldoAprox">
            Saldo en Tiendas (aprox):{" "}
            {branches && CurrencyFormat(branches.message.match(/\d+/)[0])}
          </h5>
        </div>
        <div>
          <TextField
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size="small"
            variant="outlined"
            placeholder="Buscar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, filteredItems]);
  
  const viewDetails = (row) => {
    return (
      <div className="expandTable2">
        <div className="leftColumn">
          {row.data.fullName && (
            <div className="tableIconsData">
              <img className="icon-data" src={usericon} />
              <p>Nombre del propietario: {row.data.fullName}</p>
            </div>
          )}
          {row.data.adress && (
            <div className="tableIconsData">
              <img className="icon-data" src={adress} />
              <p>{`Dirección: ${row.data.adress}`}</p>
            </div>
          )}
          {row.data.position && (
            <div className="tableIconsData">
              <img className="icon-data" src={location} />
              <p>
                Ubicación: <a href={row.data.position}>{row.data.position}</a>
              </p>
            </div>
          )}
          {row.data.phone && (
            <div className="tableIconsData">
              <img className="icon-data" src={phone} />
              <p>
                Teléfono:{" "}
                <a href={`https://wa.me/521${row.data.phone}`}>
                  {row.data.phone}
                </a>
              </p>
            </div>
          )}
          {row.data.branchID && (
          <div className="tableIconsData">
            <img className="icon-data" src={tienda} />
            <p> BranchID: {row.data.branchID}</p>
          </div>
        )}
        </div>
        <div className="rightColumn">
          <button className="blueBtn" onClick={() => openModal(row.data.branchID)}>Realizar Deposito</button>
          <button className="blueBtn" onClick={() => openGenerateTokenModal(row.data.toRecover,row.data.branchID)}>Generar Token</button>
        </div>
        <Modal isOpen={isModalOpen} onRequestClose={closeModal}  contentLabel="Realizar Depósito">
          {modalDeptoContent}
        </Modal>
        <Modal isOpen={isGenerateTokenModalOpen} onRequestClose={closeGenerateTokenModal} contentLabel="Generar Token">{generateTokenModalContent}</Modal>
      </div>
    );
  };

  return (
    <>
      <div className="container">
        {data && (
          <div className="d-flex flex-column m-2 mx-12">
            <DataTable
              columns={columns}
              data={filteredItems}
              defaultSortFieldId={3}
              expandableRows
              expandOnRowClicked={true}
              expandableRowsComponent={viewDetails}
              expandableRowsHideExpander={true}
              pagination
              paginationComponentOptions={pageOptions}
              bordered={true}
              customStyles={customStyles}
              paginationResetDefaultPage={resetPaginationToggle}
              noDataComponent={noDataComponent}
              persistTableHead
              fixedHeader
              subHeader
              subHeaderComponent={searchBar}
            />
          </div>
        )}
        <br></br>
      </div>
    </>
  );
};
const mapStateToProps = ({ branchBalance }) => {
  const { branches } = branchBalance;
  return {
    branches
  };
};

const mapDispatchToProps = {
  fetchBranchBalance: getBranchBalance,
  disableFees: disableFeesToBranch,
  loading,loading
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchBalance);