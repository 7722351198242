import axios from 'axios';
import {loadState} from '@utils/localstorage.js'
import { HOST, STAGE } from '@constants';

const instance = axios.create({ baseURL: `${HOST}/${STAGE}` });

instance.defaults.headers['User-App'] = 'DonApp';
instance.interceptors.request.use(async config => {
  const authState = loadState();
  config.headers = { 'Authorization': `Bearer ${authState.auth.token}` }
  return config;
});

instance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  console.log("instance.interceptors.response error =>",error);
  const originalRequest = error.config;
  if ((error.response.status >= 401 && error.response.status < 404) && !originalRequest._retry) {
    
    originalRequest._retry = true;
    if(typeof(originalRequest.data) === 'string')
      originalRequest.data = JSON.parse(error.config.data);
    const authState = loadState();
    const tokenResponse = await axios({method: 'post',
      url: `${HOST}/${STAGE}/api/Authentication/refresh-token`,
      data: { "Token": authState.auth.token }, headers: { 'Content-Type': 'application/json' }
    });
    const access_token = tokenResponse.data?.data;
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token.token;
    authState.auth.token = access_token.token;
    saveState({'auth': authState})
    return instance(originalRequest);
  }
  const resp = {
    data: {},
    statusCode: error.response.status,
    succeeded: false,
    message: "Ups, ocurrio un error inesperado. Estamos trabajando en ello, favor de intentarlo mas tarde",
    errors: null,
    validationErrors: null,
  }
  return resp;
});

export default instance;