import React from "react";
import { CurrencyFormat } from "@utils";
import "./money.scss";
const Money = ({ inTransit, banks, global,collected, ...props }) => {
  return (
    <div className=" moneyTable">
      <div className="transit">
        <h4 className="numberMoneyTable">
          <div> {CurrencyFormat(inTransit)}</div>
        </h4>
        <h6>Tránsito</h6>
      </div>
      <div className="transit">
        <h4 className="numberMoneyTable">
          <div> {CurrencyFormat(collected)}</div>
        </h4>
        <h6>Recolectado</h6>
      </div>
      <div className="transit">
        <h4 className="numberMoneyTable">
          <div> {CurrencyFormat(banks)}</div>
        </h4>
        <h6>Depositado</h6>
      </div>
      <div className="transit">
        <h4 className="numberMoneyTable">
          <div> {CurrencyFormat(global)}</div>
        </h4>
        <h6>Global</h6>
      </div>
    </div>
  );
};

export default Money;
