export const HOST = `https://s2xof07sq0.execute-api.us-east-1.amazonaws.com`;
export const STAGE = `Prod`;
export const LOGIN = "/";
export const APPNAME = "DonAppWeb";
export const HOME = "/Home";
export const REGISTER = "/RegisterClients";
export const PICKUPTABLE = "/PickupTable";
export const FAILEDTX = "/FailedTX";
export const ROUTEDAY = "/CollectRoutes";
export const DUE = "/Due";
