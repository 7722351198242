import React,{useEffect} from "react";
import {
  Header,
  Balance,
  SalesResume,
  BranchBalance,
  RouteDay,
} from "@components";
import { connect } from "react-redux";
import "./home.scss";
import { useNavigate } from "react-router-dom";
import { ROUTEDAY } from "@constants";

const Home = ({ user }) => {
  console.log(user);
  let navigate = useNavigate();
  function refreshPage() {
    window.location.reload();
  }
  useEffect(() => {
    if(user.role === "Pickup")
      navigate(ROUTEDAY)
  }, [user]);

  return (
    <>
      <Header />
      <div className="container ">
        {user.role === "Admin" && (
          <>
            <div className="row">
              <div className="col-md-3">
                <Balance />
              </div>
              <div className="col-md-9">
                <SalesResume />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <BranchBalance />
              </div>
            </div>

            <div>
              <button
                className="blueBtn btn-sticky"
                onClick={refreshPage}
              >
                Actualizar home
              </button>
            </div>
          </>
        )}
        {user.role === "Pickup" && navigate(ROUTEDAY)}
      </div>
    </>
  );
};
const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};
export default connect(mapStateToProps, null)(Home);

