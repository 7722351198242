import React, { useEffect, useState } from "react";
import "./routeDay.scss";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { pageOptions, CurrencyFormat } from "@utils";
import { phone, location, adress, usericon } from "@assets";
import { columns } from "@tables/routeDay";
import { useQuery } from "react-query";
import { fetchRoute, fetchBalance } from "@services/branches";
import { doCollect, nonPayment } from "@services/picklup";
import { COLORS } from "../../styles/colors";
import Modal from "../Modals/BodyModal";
import { FormModal } from "../Modals/FormModal";
import { pickup, date } from "@assets";
import Money from "../MoneyRouteday";
import { InputAdornment, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const RouteDay = ({ Pickup, ...props }) => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filteredItems, setfilteredItems] = useState();
  const [showModal, setShowModal] = useState(false);
  const [pendingBranches, setPendingBranches] = useState(null);
  const { data: currentRoute, refetch } = useQuery(
    ["route", Pickup.pickupNumber],
    () => fetchRoute(Pickup.pickupNumber)
  );
  const getBranchBalance = async (branchID) => {
    const data = await fetchBalance(branchID);
    return data.total_to_recover;
  };
  useEffect(() => {
    currentRoute ?? refetch();
  }, []);

  useEffect(() => {
    setPendingBranches(currentRoute?.branches);
  }, [currentRoute]);

  useEffect(() => {
    if(pendingBranches)
        setfilteredItems(
          pendingBranches.filter((o) => {
            return ((o.branchID && o.branchID.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
              (o.branchName && o.branchName.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
              (o.branchNumber && o.branchNumber.toString().indexOf(filterText) !== -1) ||
              (o.adress && o.adress.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
              (o.status && o.status.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) 
            );
          })
        );
  }, [filterText,pendingBranches]);

  const handleNonPayment = (branchId, pickupID) => {
    try {
      Swal.fire({
        title: "¿Estás seguro que la tienda NO realizó el pago?",
        text: "¡No podrás revertir esto!",
        imageUrl: "https://i.ibb.co/z2dGLSf/noPago.png",
        imageHeight: 120,
        showCancelButton: true,
        confirmButtonColor: "#6533ff",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "NO PAGÓ",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await nonPayment({
            BranchID: branchId,
            PickupNumber: Pickup.pickupNumber,
          });
          if (response.succeeded) {
            refetch();
            Swal.fire("Operación realizada con éxito");
          } else Swal.fire(response.message, "", "error");
        }
      });
    } catch (error) {
      console.log("Error, vuelva a intentarlo más tarde");
    }
  };

  const collect = async (branchId) => {
    Swal.fire({
      title: "Saldo por recuperar",
      imageUrl: "https://i.ibb.co/k2PN24H/recuperarse.png",
      imageHeight: 120,
      html: "Saldo por entregar <b></b>",
      didOpen: async () => {
        const balanceToShow = await getBranchBalance(branchId);
        const b = Swal.getHtmlContainer().querySelector("b");
        b.textContent = CurrencyFormat(balanceToShow);
      },
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Completa",
      denyButtonText: `Parcial`,
      cancelButtonText: `Cancelar`,
      confirmButtonColor: "#6533ff",
      denyButtonColor: `#2fb1ea`,
      cancelButtonColor: "#8F8F8F",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Ingresa el token de 6 dígitos",
          imageUrl: "https://i.ibb.co/168xY1V/dar-Dinero.png",
          imageHeight: 120,
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Validar token",
          showLoaderOnConfirm: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const b = await getBranchBalance(branchId);
            const response = await doCollect({
              BranchID: branchId,
              PickupNumber: Pickup.pickupNumber,
              PickupToken: result.value,
              ExpectedAmount: b,
              CollectedAmount: b,
            });
            if (response.succeeded) {
              refetch();
              Swal.fire("Recolección exitosa!", "", "success");
            } else Swal.fire(response.message, "", "error");
          }
        });
      } else if (result.isDenied) {
        Swal.fire({
          title: "Recolección parcial",
          imageUrl: "https://i.ibb.co/168xY1V/dar-Dinero.png",
          imageHeight: 110,
          html:
            '<form action="" class="m-auto" style="max-width:600px; overflow:hidden">' +
            '<div class="form-group mb-2 row"><label for="token1" class="col-md-2 col-form-label">Token</label>' +
            '<div class="col-md-7"><input type="text" class="form-control form-control-lg" id="Token" name="token" required></div>' +
            "</div>" +
            '<div class="form-group mb-2 row"><label for="monto2" class="col-md-2 col-form-label">Monto</label>' +
            '<div class="col-md-7"><input type="number" class="form-control form-control-lg" id="CollectedAmount" name="monto" required></div>' +
            "</div>" +
            "</form>",
          focusConfirm: false,
          preConfirm: async (pre) => {
            
            const b = await getBranchBalance(branchId);
            const token = document.getElementById("Token").value;
            const collected = parseFloat(
              document.getElementById("CollectedAmount").value
            );
            const response = await doCollect({
              BranchID: branchId,
              PickupNumber:  Pickup.pickupNumber,
              PickupToken: token,
              ExpectedAmount: b,
              CollectedAmount: collected,
            });
            if (response.succeeded) {
              refetch();
              Swal.fire("Recolección exitosa!", "", "success");
            } else Swal.fire(response.message, "", "error");
          },
        });
      }
    });
  };

  const expandRow = (row) => {
    return (
      <div className="expandTable expandTableRouteDay">
        <div className="btnsTable">
          <button
            type="button"
            onClick={() => handleNonPayment(row.data.branchId)}
            className="btn btn-outline-danger btn-sm btnRouteDay"
          >
            La tienda no realizó pago
          </button>
          <button
            type="button"
            className="btn btn-success btn-sm  btnRouteDay"
            onClick={() => {
              collect(row.data.branchId);
            }}
          >
            Recolección de tienda
          </button>
        </div>
        <div className="tableIconsData">
          <img className="icon-data" src={usericon}></img>{" "}
          <p>Nombre: {row.data.branchName}</p>
          {/*<p>Nombre: {row.fullName}</p>*/}
        </div>
        <div className="tableIconsData">
          <img className="icon-data" src={phone}></img>{" "}
          <p>Teléfono: {row.data.phoneNumber}</p>
        </div>
        <div className="tableIconsData">
          {" "}
          <img className="icon-data" src={adress}></img>{" "}
          <p>Dirección:{row.data.adress}</p>
        </div>
        <div className="tableIconsData">
          <img className="icon-data" src={location}></img>{" "}
          <p className="navegation">
            Navegación: <a href={`https://www.google.com/maps/dir/?api=1&destination=${row.data.latitude},${row.data.longitude}&travelmode=driving`}>https://www.google.com/maps/dir/?api=1&destination={row.data.latitude},{row.data.longitude}&travelmode=driving</a> 
          </p>
        </div>
      </div>
    );
  };

  const customStyles = {
    headCells: {
      style: {
        color: COLORS.white,
        fontWeight: 600,
        paddingRight: 0,
        fontSize: 14,
        backgroundColor: " #6533ff",
      },
    },
    table: {
      style: {
        maxHeight: "400px !important",
        overflow: "auto",
      },
    },
    headRow: {
      style: {
        zIndex: "0 !important",
      },
    },
    subHeader: {
      style: {
        padding: "0 10",
        marginBottom: 30,
        justifyContent: "space-between",
      },
    },
    rows: {
      style: {
        fontSize: 14,
      },
    },
  };

  const searchBar = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div>
          <TextField
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size="small"
            variant="outlined"
            placeholder="Buscar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, filteredItems]);

  return (
    <>
      <div className="container">
        <div className="divTextRoute">
          <p className="textRoute">
            <img className="icon-data" src={pickup}></img>Pickup Name:{" "}
            {Pickup && Pickup.name}{" "}
          </p>
          <p className="textRoute">
            <img className="icon-data" src={date}></img>Fecha:{" "}
            {currentRoute && currentRoute.date}
          </p>
        </div>

        <Money
          global={Pickup && Pickup.inTransit}
          inTransit={currentRoute && currentRoute.inTransit}
          banks={currentRoute && currentRoute.deposited}
          collected={currentRoute && currentRoute.collected}
        />
        {pendingBranches && (
          <div className="d-flex flex-column m-2 mx-12">
            <DataTable
              columns={columns}
              data={filteredItems}
              //data={pendingBranches}
              defaultSortFieldId={1}
              expandableRows
              expandOnRowClicked={true}
              expandableRowsComponent={expandRow}
              expandableRowsHideExpander={true}
              pagination
              paginationComponentOptions={pageOptions}
              bordered={true}
              customStyles={customStyles}
              fixedHeader
              subHeader
              subHeaderComponent={searchBar}
            />
          </div>
        )}

        <button
          className="blueBtn btn-sticky"
          onClick={() => setShowModal(!showModal)}
        >
          Registrar depósito
        </button>
        <Modal state={showModal} changeState={setShowModal}>
          <FormModal token={Pickup.pickupNumber} changeState={setShowModal} />
        </Modal>
      </div>
    </>
  );
};

export default RouteDay;
