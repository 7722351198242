import * as types from '../types';

const initialState = {
  branches: null,
  due: null,
};

function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.SET_BRANCH_BALANCE:
      return { ...state, branches: payload };
    case types.SET_DUE_ACCOUNTS:
      return { ...state, due: payload };
    default:
      return state;
  }
}

export default reducer;
