import axios from '@utils/services'
import { DateOnlyNow } from '@utils';

export const fetchResume = async () => {
    try {
      let date = DateOnlyNow();
      const response = await axios.post('/api/Balance/GetAllSalesResume',{ Date : DateOnlyNow() });
      return response.data.data;
    } catch (err) {
      console.log(err.response);
    }
  };

export default { fetchResume }