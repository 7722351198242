import React from "react";
import { Header,PickupResume } from "@components";

const PickupTable = () => {
  return (
    <>
      <Header />
      <PickupResume />
    </>
  );
};
export default PickupTable;

