import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { logo2 } from "@assets";
import { signIn } from "@redux/actions/auth";
import { HOME, ROUTEDAY } from "@constants";
import { useNavigate } from "react-router-dom";
import "./login.scss";
const LogIn = ({ signIn, isValid, token, history, user, ...props }) => {
  let navigate = useNavigate();
  useEffect(() => {
    if (isValid) {
      if (user.role == "Pickup") navigate(ROUTEDAY);
      else navigate(HOME);
    }
  }, [isValid]);
  const required = (value) => (value ? undefined : " *Campo requerido");
  const handleLogin = async (values) => {
    await signIn(values.user, values.password);
  };

  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <img className="logo2" src={logo2} />
        <Form
          onSubmit={handleLogin}
          render={({
            handleSubmit,
            form,
            pristine,
            submitting,
            valid,
            values,
          }) => (
            <form onSubmit={handleSubmit} className="px-2">
              <h3 id="title-login" className="text-center">
                Iniciar Sesión
              </h3>

              <div className="d-flex flex-column m-2 mx-4">
                <Field
                  name="user"
                  component="input"
                  placeholder="Usuario"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="d-flex px-2">Ingresa tu celular:</label>
                      <input
                        className="d-flex px-3 input-login"
                        {...input}
                      ></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="d-flex flex-column  m-2 mx-4">
                <Field
                  name="password"
                  component="input"
                  type="password"
                  placeholder="Contraseña"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="d-flex px-2">
                        Ingresa tu contraseña:
                      </label>
                      <input
                        className="d-flex px-3 input-login"
                        {...input}
                      ></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <button
                className="btn-login"
                type="submit"
                disabled={submitting || pristine}
              >
                <span> Entrar </span>
                <svg width="15px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </button>
            </form>
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { errorMessage, token, isValid, userName, user } = auth;
  return { errorMessage, token, isValid, userName, user };
};

const mapDispatchToProps = {
  signIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
