import React, { useState } from "react";
import "./header.scss";
import Image from "@components/Image";
import { identificador } from "@assets";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "@constants";
import { signOut } from "@redux/actions/auth";
import { connect } from "react-redux";

const Header = ({ signOut, user, ...props }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  let navigate = useNavigate();
  const handleLogOut = async () => {
    await signOut();
    navigate(LOGIN);
  };
  return (
    <nav
      id="mainNavbar"
      className="navbar navbar-dark navbar-expand-lg bg-primary py-0 fixed-top"
    >
      <div className="container-fluid">
        <a className="nav-link" aria-current="page" href="/Home">
          <Image className="identificador" src={identificador} alt="logo" />
        </a>

        <button
          className="custom-toggler navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapseNavMenu"
          aria-controls="collapseNavMenu"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse `}
          id="collapseNavMenu"
        >
          <ul className="navbar-nav justify-content-between col-md-12">
            {user.role === "Admin" && (
              <>
                <li className="nav-item li-header">
                  <a className="nav-link" aria-current="page" href="/Home">
                    Home
                  </a>
                </li>

                <li className="nav-item li-header">
                  <a className="nav-link" href="/Due">
                    Vencidas
                  </a>
                </li>
                <li className="nav-item li-header">
                  <a className="nav-link" href="/FailedTX">
                    Tx Fallidas
                  </a>
                </li>
                <li className="nav-item li-header cliente">
                  <a className="nav-link" href="/RegisterClients">
                    Agregar cliente
                  </a>
                </li>
                <li className="nav-item li-header">
                  <a className="nav-link" href="/CollectRoutes">
                    Rutas
                  </a>
                </li>
              </>
            )}
            {user.role === "Pickup" && (
              <>
                <li className="nav-item li-header">
                  <a className="nav-link" aria-current="page" href="/Home">
                    Home
                  </a>
                </li>

                <li className="nav-item li-header">
                  <a className="nav-link" href="/Due">
                    Vencidas
                  </a>
                </li>
              </>
            )}
            <li>
              <div className="usuario">
                Usuario: {user.role} | {user.name}
              </div>
            </li>
            <li className="nav-item li-header nav-link">
              <button id="btnLogOut" onClick={handleLogOut}>
                Cerrar sesión
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapDispatchToProps = {
  signOut,
};
const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
