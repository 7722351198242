import axios from '@utils/services'

export const fetchBranchBalance = async () => {
    try {
      const response = await axios.post('/api/BackOffice/GetBranchBalance',{BranchNumber : ""});
      return response.data;
    } catch (err) {
      console.log(err.response);
    }
  };

  export const fetchDue = async () => {
    try {
      const response = await axios.post('/api/BackOffice/DueDones',{});
      return response.data;
    } catch (err) {
      console.log(err.response);
    }
  };

export const disableFees = async (branchNumber) => {
    try {
      const response = await axios.post('/api/BackOffice/DisableFees',{BranchNumber : branchNumber});
      return response.data;
    } catch (err) {
      console.log(err.response);
    }
  };

export const doDepositToBranch = async (deposit) => {
  try{
    const response = await axios.post('/api/Collect/DoDeposit',deposit);
    return response.data;
  } catch(err){
    console.log(err.response)
  }
}

export const getDonToken = async (request) => {
  try{
    const response = await axios.post('/api/Collect/GenerateDonCollectToken',request);
    return response.data;
  } catch(err){
    console.log(err)
  }
}

export default { fetchBranchBalance,fetchDue,doDepositToBranch,getDonToken }