// LOADING
export const LOADING = 'LOADING'

export const SET_USER_DISPLAY_DATA = "SET_USER_DISPLAY_DATA"
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_VALID_TOKEN = 'SET_VALID_TOKEN'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SET_BALANCE = 'SET_BALANCE'
export const SET_OPERATIONS = 'SET_OPERATIONS'
export const AUTH_LOCAL = 'AUTH_LOCAL'
export const AUTH_LOCAL_OBJECT = 'AUTH_LOCAL_OBJECT'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_MENU = 'SET_MENU'
export const SET_SUBCATEGORY = 'SET_SUBCATEGORY'
export const SET_SEGMENT = 'SET_SEGMENT'
export const SET_CART = 'SET_CART'
export const SET_PRODUCTS = 'SET_PRODUCTS' 
export const SET_RESUME = 'SET_RESUME'
export const SET_BRANCH_BALANCE = 'SET_BRANCH_BALANCE'
export const SET_BRANCHES = 'SET_BRANCHES'
export const SET_DUE_ACCOUNTS = 'SET_DUE_ACCOUNTS'