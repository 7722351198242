import { createBranch,fetchBranches } from '@services/branches';
import * as types from '@redux/types';

export const createNewBranch = (branch) => async dispatch => {
    try {
        dispatch({ type: types.LOADING });
        var data = await createBranch(branch);
        dispatch({ type: types.LOADING });
        return data; 
    }
    catch (error) {
        console.log(error)
        dispatch({ type: types.LOADING });
    }
};

export const getAllBranches = () => async dispatch => {
    try{
        dispatch({ type: types.LOADING });
        var data = await fetchBranches();
        dispatch({ type: types.LOADING });
        return data; 
    }
    catch (error) {
        console.log(error)
        dispatch({ type: types.LOADING });
    }
}