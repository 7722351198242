import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import {
  getDueAccounts,
  disableFeesToBranch,
} from "@redux/actions/branchBalance";
import { CurrencyFormat, pageOptions, noDataComponent } from "@utils";
import { phone, location, adress, usericon, tienda } from "@assets";
import { columns } from "@tables/due";
import DataTable from "react-data-table-component";
import { InputAdornment, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { COLORS } from "../../styles/colors";
import "./tableDueBalance.scss";

const DueAccounts = ({ due, fetchDue, disableFees }) => {
  const [filterText, setFilterText] = React.useState("");
  const [data, setData] = useState(["Active", "Average", "OverDue", "Lost"]);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [filteredItems, setfilteredItems] = useState();

  useEffect(() => {
    if (data)
      setfilteredItems(
        data.filter((o) => {
          return ((o.branchID && o.branchID.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.branchName && o.branchName.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.branchNumber && o.branchNumber.toString().indexOf(filterText) !== -1) ||
            (o.userName && o.userName.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.adress && o.adress.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
            (o.status && o.status.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
          );
        })
      );
  }, [filterText, data]);

  useEffect(() => {
    due ?? fetchDue();
    if (due) {
      setData(due.data);
    }
  }, [due]);

  const customStyles = {
    headCells: {
      style: {
        color: COLORS.white,
        fontWeight: 600,
        paddingRight: 0,
        fontSize: 14,
        backgroundColor: " #6533ff",
      },
    },
    table: {
      style: {
        maxHeight: "400px !important",
        overflow: "auto",
      },
    },
    headRow: {
      style: {
        zIndex: "0 !important",
      },
    },
    subHeader: {
      style: {
        padding: "0 10",
        marginBottom: "30px ",
        justifyContent: "space-between ",
      },
    },
    rows: {
      style: {
        fontSize: 14,
      },
    },
  };

  const handleDisableFees = async (branchNumber, checked) => {
    await disableFees(branchNumber);
  };

  const searchBar = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div>
          <TextField
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size="small"
            variant="outlined"
            placeholder="Buscar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, filteredItems]);

  const viewDetails = (row) => {
    return (
      <div className="expandTable">
        {row.data.fullName && (
          <div className="tableIconsData">
            <img className="icon-data" src={usericon} />
            <p>Nombre del propietario: {row.data.fullName}</p>
          </div>
        )}
        {row.data.adress && (
          <div className="tableIconsData">
            <img className="icon-data" src={adress} />
            <p>{`Dirección: ${row.data.adress}`}</p>
          </div>
        )}
        {row.data.phone && (
          <div className="tableIconsData">
            <img className="icon-data" src={phone} />
            <p>
              Teléfono:{" "}
              <a href={`https://wa.me/521${row.data.phone}`}>
                {row.data.phone}
              </a>
            </p>
          </div>
        )}

        {row.data.branchID && (
          <div className="tableIconsData">
            <img className="icon-data" src={tienda} />
            <p> BranchID: {row.data.branchID}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="container">
        {data && (
          <div className="d-flex flex-column m-2 mx-12">
            <DataTable
              columns={columns}
              data={filteredItems}
              defaultSortFieldId={3}
              expandableRows
              expandOnRowClicked={true}
              expandableRowsComponent={viewDetails}
              expandableRowsHideExpander={true}
              pagination
              paginationComponentOptions={pageOptions}
              bordered={true}
              customStyles={customStyles}
              paginationResetDefaultPage={resetPaginationToggle}
              noDataComponent={noDataComponent}
              persistTableHead
              fixedHeader
              subHeader
              subHeaderComponent={searchBar}
            />
          </div>
        )}
        <br></br>
      </div>
    </>
  );
};
const mapStateToProps = ({ branchBalance }) => {
  const { due } = branchBalance;
  return {
    due,
  };
};

const mapDispatchToProps = {
    fetchDue: getDueAccounts
};

export default connect(mapStateToProps, mapDispatchToProps)(DueAccounts);
