import axios from '@utils/services'

export const fetchFailedTx = async () => {
    try {
        const response = await axios.post('/api/BackOffice/GetFailedTx',{ Days : 1 });
        return response.data.data;

     }
    catch (err) {
        console.log(err.response);
    }
}

export default { fetchFailedTx }