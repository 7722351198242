import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import ErrorPage from "@components/ErrorPage/ErrorPage";

function PrivateRoute({ children, isValid, user, ...props }) {
  const isRole = user.role === props.role || props.role === undefined;
  if(isValid && user.role === "Admin")
    return children;
  if(isValid && isRole)
    return children;
  else if(!isRole)
    return <ErrorPage />
  else
    return <Navigate to="/" />
}

const mapStateToProps = ({ auth }) => {
  const { isValid, user } = auth;
  return { isValid, user };
};
export default connect(mapStateToProps, null)(PrivateRoute);
