import {createStore, applyMiddleware, compose } from 'redux';
import Reducers from '../reducers';
import thunk from 'redux-thunk';
import {loadState, saveState} from '@utils/localstorage.js'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialData = loadState()
const configureStore = () => {
  let store = createStore(Reducers, {auth: initialData?.auth}, composeEnhancers(applyMiddleware(thunk)));
  //let store = createStore(Reducers, {}, composeEnhancers(applyMiddleware(thunk)));
  return store;
};

let store = configureStore()

store.subscribe( function () {
  saveState(store.getState())
})

export default store;
