import { CurrencyFormat, PercentFormat,DateFormat,getDayOfWeekName } from "@utils"

 export const columns = [
    { selector: row => row.branchNumber, name: "Numero Tienda", sortable: true, wrap: true},
    { selector: row => row.branchName, name: "Nombre", sortable: true, wrap: true},
    { selector: row => row.fullName, name: "Dueño", sortable: true, wrap: true,omit:true},
    { selector: row => row.phone, name: "Telefono", sortable: true, wrap: true},
    { selector: row => CurrencyFormat(row.toRecover), name: "Total a Recuperar", sortable: true, wrap: true},
    { selector: row => DateFormat(row.lastRecolection), name: "Ultima Recolección", sortable: true, wrap: true},
    { selector: row => Math.round(row.days), name: "Dias", sortable: true, wrap: true},
    { selector: row => getDayOfWeekName(row.recollectionDay), name: "Ruta", sortable: true, wrap: true},
    { selector: row => row.branchID, name: "branchID", sortable: true, wrap: true,omit: true},
    { selector: row => row.adress, name: "Direccion", sortable: true, wrap: true,omit: true},
  ];

 
export default { columns }