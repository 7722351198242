import { fetchBranchBalance,disableFees,fetchDue,doDepositToBranch,getDonToken } from '@services/branchBalance';
import * as types from '@redux/types';

//import { fetchOperations } from '../services/balance';

export const setBranchBalance = balance => {
    return {
        type: types.SET_BRANCH_BALANCE,
        payload: balance,
    };
};

export const setDueAccounts = accounts => {
    return {
        type: types.SET_DUE_ACCOUNTS,
        payload: accounts,
    };
};


export const getBranchBalance = () => async dispatch => {
    try {
        dispatch({ type: types.LOADING });
        dispatch(setBranchBalance(await fetchBranchBalance()));
        dispatch({ type: types.LOADING });
    }
    catch (error) {
        console.log(error)
        dispatch({ type: types.LOADING });
    }
};
export const getDueAccounts = () => async dispatch => {
    try {
        dispatch({ type: types.LOADING });
        dispatch(setDueAccounts(await fetchDue()));
        dispatch({ type: types.LOADING });
    }
    catch (error) {
        console.log(error)
        dispatch({ type: types.LOADING });
    }
};

export const disableFeesToBranch = (branchNumber) => async dispatch => {
    try {
        dispatch({ type: types.LOADING });
        await disableFees(branchNumber);
        dispatch(setBranchBalance(await fetchBranchBalance()));
        dispatch({ type: types.LOADING });
    }
    catch (error) {
        console.log(error)
        dispatch({ type: types.LOADING });
    }
};

export const loading = () => async dispatch => {
    dispatch({ type: types.LOADING });
}

export const doDeposit = (deposit) => async dispatch => {
    try {
        dispatch({ type: types.LOADING });
        await doDepositToBranch(deposit);
        dispatch({type: "STATUS",payload: "Success!"});
        dispatch({ type: types.LOADING });
    }
    catch (error) {
        console.log(error)
        dispatch({ type: types.LOADING });
    }
};

export const generateDonToken = (request) => async dispatch => {
    try {
        dispatch({ type: types.LOADING });
        dispatch(setDonToken(await getDonToken(request)));
        dispatch({ type: types.LOADING });
    }
    catch (error) {
        console.log(error)
        setDonToken({token: "Error!"});
        dispatch({ type: types.LOADING });
    }
};