import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./registerClients.scss";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { createNewBranch } from "@redux/actions/branches";
import { Form, Field } from "react-final-form";
import { required } from "@utils";
import { Header } from "@components";
import { registrar } from "@assets";
const RegisterClients = ({ createBranch }) => {
  const [createResponse, setResponse] = useState(null);
  const navigate = useNavigate();

  const handleCreate = async (values) => {
    let response = await createBranch(values);
    setResponse(response);
  };

  useEffect(() => {
    if (createResponse) {
      if (createResponse.succeeded) {
        // Todo salio chido :D
        Swal.fire({
          position: "center",
          icon: "success",
          title: '<strong>Usuario creado con éxito</strong>',
          icon: 'success',
          html: `<a href="${createResponse.data}">Enviar Bienvenida!</a>`
        })
        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   title: "Usuario creado con éxito",
        //   showConfirmButton: true,
        //   text: createResponse.data,
        // });
        navigate("/home");
      } else if (createResponse["errors"] != "") {
        // Hubo errores
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Error",
          showConfirmButton: false,
          timer: 1500,
          text: createResponse["errors"] ?? "Ups, ocurrio un error",
        });
      } // Algo salio mal :(
    }
  }),
    [createResponse];

  return (
    <>
      <Header />

      <h1 className="title-form">Registro de nuevos clientes</h1>

      <div className="container-register">
        <Form
          onSubmit={handleCreate}
          render={({
            handleSubmit,
            form,
            pristine,
            submitting,
            valid,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <br />
              <h3 className="subtitle-form">&#9998;Datos personales</h3>
              <br />
              <div className="form-inline">
                <Field
                  name="FirstName"
                  placeholder="Primer Nombre"
                  component="input"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="FirstName">
                        Primer nombre:
                      </label>

                      <input className="input-form" {...input}></input>

                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <Field
                  name="MiddleName"
                  placeholder="Segundo Nombre"
                  component="input"
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="MiddleName">
                        Segundo nombre:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <Field
                  name="LastName"
                  placeholder="Apellido"
                  component="input"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="LastName">
                        Apellido:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <Field
                  name="BranchName"
                  placeholder="Nombre de ta tienda"
                  component="input"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="BranchName">
                        Nombre de la tienda:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <h3 className="subtitle-form">&#9998;Datos de contacto</h3>

              <div className="form-inline">
                <Field
                  name="Phone"
                  placeholder="Telefono"
                  component="input"
                  pattern="[0-9]{10}"
                  title="Ingresa únicamente los 10 digitos de tu número telefónico"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="Phone">
                        Telefono:
                      </label>
                      <input
                        type="phone"
                        pattern="[0-9]{10}"
                        className="input-form"
                        {...input}
                      />
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <Field
                  name="eMail"
                  placeholder="Correo"
                  component="email"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="eMail">
                        Correo electrónico:
                      </label>
                      <input
                        type="email"
                        className="input-form"
                        {...input}
                      ></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <Field name="TaxId" placeholder="TaxId" component="input">
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="TaxId">
                        RFC:
                      </label>
                      <input className="input-form" {...input}></input>
                    </>
                  )}
                </Field>
              </div>

              <h3 className="subtitle-form">&#9998;Dirección</h3>

              <div className="form-inline">
                <label className="label-form" htmlFor="State">
                  Estado:
                </label>
                <Field className="input-form" name="State" component="select">
                  <option value="Colima">Colima</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                </Field>

                <label className="label-form" htmlFor="City">
                  Ciudad:
                </label>
                <Field className="input-form" name="City" component="select">
                  <option />
                  <option value="Colima">Colima</option>
                  <option value="Villa de Alvarez">Villa de Alvarez</option>
                  <option value="Comala">Comala</option>
                  <option value="Cuauhtemoc">Cuauhtemoc</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                </Field>
                <Field name="Street" placeholder="Street" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="Street">
                        Calle:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>

                <Field
                  name="IntNumber"
                  placeholder="IntNumber"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="IntNumber">
                        Número de casa:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>

                <Field
                  name="Neighborhood"
                  placeholder="Neighborhood"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="Neighborhood">
                        Colonia:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>

                <Field
                  name="PostalCode"
                  placeholder="PostalCode"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="PostalCode">
                        Código Postal:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <h3 className="subtitle-form">&#9998;Recolección</h3>

              <div className="form-inline">
                <Field
                  name="Position"
                  placeholder="Position"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="Position">
                        Ubicación:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <Field
                  name="Lat"
                  placeholder="Lat"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="Lat">
                        Latitud:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <Field
                  name="Long"
                  placeholder="Long"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="label-form" htmlFor="Long">
                        Longitud:
                      </label>
                      <input className="input-form" {...input}></input>
                      {meta.error && meta.touched && (
                        <span className="text-danger">{meta.error}</span>
                      )}
                    </>
                  )}
                </Field>
                <label className="label-form" htmlFor="Region">
                  Región:
                </label>
                <Field className="input-form" name="Region" component="select">
                  <option value="01">01</option>
                  <option value="02">02</option>
                </Field>

                <label className="label-form" htmlFor="Zone">
                  Zona:
                </label>
                <Field className="input-form" name="Zone" component="select">
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                </Field>

                <label className="label-form" htmlFor="PickupRelated">
                  Recolector:
                </label>

                <Field
                  className="input-form"
                  name="PickupRelated"
                  component="select"
                >
                  <option value="0101004">Jocsan</option>
                  <option value="0101002">Jerry</option>
                  <option value="0101003">Pily</option>
                  <option value="0101008">Miriam</option>
                  <option value="0101002">Julio Carbajal</option>
                  <option value="0101005">Jorge</option>
                </Field>
              </div>
              <br />
              <div className="form-inline">
                <button disabled={submitting || pristine} className="enviar">
                  <div className="reg-wrapper-1">
                    <div className="reg-wrapper">
                      <img
                        className="reg"
                        src={registrar}
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      ></img>
                    </div>
                  </div>
                  <span> Registrar</span>
                </button>
              </div>
            </form>
          )}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  createBranch: createNewBranch,
};

export default connect(null, mapDispatchToProps)(RegisterClients);
