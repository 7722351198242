import { CurrencyFormat, DateTimeFormat } from "@utils"
export const repColumns = [
  { selector: row => row.pickupNumber, name: "Pickup Number", sortable: true, wrap: true},
  { selector: row => row.name, name: "Nombre", sortable: true, wrap: true},
  { selector: row => CurrencyFormat(row.inTransit), name: "En tránsito", sortable: true, wrap: true},
  { selector: row => row.pickupToken, name: "Token", sortable: true, wrap: true},
  { selector: row => DateTimeFormat(row.dueDateToken), name: "Vigencia", sortable: true, wrap: true},
]
export const tokenColumns = [
  { selector: row => row.token, name: "Token", sortable: true, wrap: true},
  { selector: row => CurrencyFormat(row.inTransit), name: "En tránsito", sortable: true, wrap: true},
]

export const recollectionColumns = [
  
  { selector: row => row.branchNumber, name: "Branch Number", sortable: true, wrap: true},
  { selector: row => row.branchName, name: "Branch Name", sortable: true, wrap: true},
  { selector: row => DateTimeFormat(row.recollectionDate), name: "Fecha", sortable: true, wrap: true},
  { selector: row => row.token, name: "Token", sortable: true, wrap: true},  
  ]
export const depositColumns = [
  { selector: row => CurrencyFormat(row.amount), name: "Monto", sortable: true, wrap: true},
  { selector: row => row.bankAuth, name: "Auth", sortable: true, wrap: true},
  { selector: row => DateTimeFormat(row.depositDate), name: "Fecha", sortable: true, wrap: true},
  { selector: row => row.backAccount, name: "Cuenta", sortable: true, wrap: true},
  { selector: row => row.pickupToken, name: "Token", sortable: true, wrap: true},  
  ]

export default { repColumns, tokenColumns,recollectionColumns,depositColumns }