import { PhoneFormat } from '@utils';
import * as types from '../types';

const initialState = {
  user: {
    name: '',
    phone: '',
    role: ''
  },
  refreshToken: null,
  token: null,
  isLoading: false,
  errorMessage: '',
  branchID:'',
  isValid : false,
  userName : '',
  rememberme: true,
};


function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case types.AUTH_ERROR: {
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    }
    case types.SET_USER_DATA: {
      const { token, refreshToken, displayName, branchID,userName } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          name: displayName,
          phone: PhoneFormat(userName),
          role: payload.role,
        },
        userName,
        refreshToken,
        token,
        errorMessage: '',
        branchID
      };
    }
    case types.SET_USER_DISPLAY_DATA: {
      const { displayName, userName } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          name: displayName,
          phone: PhoneFormat(userName)
        },
        errorMessage: '',
      };
    }
    case "SET_VALID_TOKEN": {
      return {
        ...state,
        isValid: payload
      };
    }
    default:
      return state;
  }
}

export default reducer;
