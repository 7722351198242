import axios from '@utils/services'

export const createBranch = async (branch) => {
    try {
      console.log(branch);
      
      const response = await axios.post('/api/BackOffice/CreateDon',branch);
      return response.data;
    } catch (err) {
      console.log(err.response);
    }
  };

export const fetchBranches = async () => {
  try {
    const response = await axios.get('api/BackOffice/GetBranches')
    return response.data;
  } catch(error) {
    console.log(error.response);
  }
}

export const fetchRoute = async(PickupNumber) => {
  try{
    const response = await axios.post('api/Collect/GetRoute', { RouteId : '',PickupNumber : PickupNumber })
    return response.data.data;
  }
  catch(error){
    console.log(error);
  }
  return null;
}

export const fetchBalance = async(branchID) => {
  try{
    const response = await axios.post('api/Balance/GetBalanceToCollect', { BranchID : branchID })
    return response.data.data;
  }
  catch(error){
    console.log(error);
  }
  return null;
}

export default { createBranch,fetchBranches,fetchRoute, fetchBalance }