import React, { useEffect, useState } from "react";
import Modal from "../Modals/BodyModal";
import { FormModal } from "../Modals/FormModal";
import DataTable from "react-data-table-component";
import { fetchPickups } from "@services/picklup";
import { useQuery } from "react-query";
import "./pickupTable.scss";
import { tokenIcon, depoIcon, recoIcon } from "@assets";
import { connect } from "react-redux";
import { COLORS } from "../../styles/colors";
import { pageOptions, noDataComponent } from "@utils";
import {
  repColumns,
  tokenColumns,
  recollectionColumns,
  depositColumns,
} from "@tables/pickup";

const PickupResume = ({ pickupID, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const { data: pickups, refetch } = useQuery("pickups", fetchPickups);
  useEffect(() => {
    pickups ?? handleUpdate();
  }, []);
  const handleUpdate = () => {
    refetch();
  };
  const customStyles = {
    headCells: {
      style: {
        color: COLORS.white,
        fontWeight: 600,
        paddingRight: 0,
        fontSize: 14,
        backgroundColor: " #6533ff",
      },
    },
    table: {
      style: {
        maxHeight: "400px !important",
        overflow: "auto",
      },
    },
    headRow: {
      style: {
        zIndex: "0 !important",
      },
    },
    subHeader: {
      style: {
        padding: "0 10",
        marginBottom: 30,
        justifyContent: "space-between",
      },
    },
    rows: {
      style: {
        fontSize: 14,
      },
    },
  };
  const expandRow = (row) => {
    return (
      <div>
        <h5 className="title-table">
          <img className="icon-data" src={tokenIcon} />
          Tokens
        </h5>
        <DataTable
          id="table-tokens"
          keyField="token"
          columns={tokenColumns}
          data={row.data.tokens}
          defaultSorted={[{ dataField: "inTransit", order: "desc" }]}
          bordered={false}
          pagination
          paginationComponentOptions={pageOptions}
          noDataComponent={noDataComponent}
          striped
          wrapperClasses="table-responsive"
          customStyles={customStyles}
          fixedHeader
        />
        <h5 className="title-table">
          <img className="icon-data" src={recoIcon} />
          Recolecciones
        </h5>
        <DataTable
          id="table-recollections"
          keyField="operationID"
          columns={recollectionColumns}
          data={
            [].concat
              .apply(
                [],
                row.data.tokens.map((u, i) => {
                  return u.recollections;
                })
              )
              .filter((element) => {
                return element !== null;
              }) ?? []
          }
          defaultSorted={[{ dataField: "recollectionDate", order: "desc" }]}
          bordered={false}
          pagination
          paginationComponentOptions={pageOptions}
          noDataComponent={noDataComponent}
          striped
          wrapperClasses="table-responsive"
          customStyles={customStyles}
          fixedHeader
        />
        <h5 className="title-table">
          <img className="icon-data" src={depoIcon} />
          Depósitos
        </h5>
        <DataTable
          id="table-deposit"
          keyField="bankAuth"
          columns={depositColumns}
          data={
            [].concat
              .apply(
                [],
                row.data.tokens.map((u, i) => {
                  return u.deposit;
                })
              )
              .filter((element) => {
                return element !== null;
              }) ?? []
          }
          defaultSorted={[{ dataField: "depositDate", order: "desc" }]}
          bordered={false}
          pagination
          paginationComponentOptions={pageOptions}
          noDataComponent={noDataComponent}
          striped
          wrapperClasses="table-responsive"
          customStyles={customStyles}
          fixedHeader
        />
      </div>
    );
  };

  return (
    <>
      <div className="container containerStyle">
        <h1 className="viewTitle ">Pickup</h1>
        {pickups && (
          <DataTable
            keyField="pickupNumber"
            columns={repColumns}
            data={pickups}
            bordered={false}
            headerClasses="header-class"
            striped
            wrapperClasses="table-responsive"
            expandableRows
            expandOnRowClicked={true}
            expandableRowsComponent={expandRow}
            expandableRowsHideExpander={true}
            pagination
            paginationComponentOptions={pageOptions}
            customStyles={customStyles}
            fixedHeader
          />
        )}
        <br />
        <button className="blueBtn btn-sticky" onClick={handleUpdate}>
          Actualizar
        </button>
      </div>
      <div>
        {pickups && (
          <Modal state={showModal} changeState={setShowModal}>
            <FormModal token={pickupID} changeState={setShowModal} />
          </Modal>
        )}
      </div>
    </>
  );
};
const mapStateToProps = ({ auth }) => {
  const { branchID } = auth;
  return { pickupID: branchID };
};
export default connect(mapStateToProps, null)(PickupResume);
