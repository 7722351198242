import axios from 'axios';
import { HOST, STAGE } from '@constants';

export const login = async (UserName, Password) => {
    try {
      console.log(UserName);
      console.log(Password);
      const response = await axios({
        method: 'post',
        url: `${HOST}/${STAGE}/api/Authentication/authenticate`,
        data: { UserName, Password },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      response.data.data.isValid = true;
      return response.data.data;
    } catch (err) {
      throw Error(err);
    }
  };