import React from "react";
import "./bodyModal.scss";
import { close, card } from "@assets";

const Modal = ({ children, state, changeState }) => {
  return (
    <>
      {state && (
        <div className="modal-background">
          <div className="modal-container">
            <div className="modal-title">
              <h1 className="titleModal">
                {" "}
                <img className="card" src={card} /> Depósito
              </h1>
            </div>
            <button
              className="close-btn btn-sticky"
              onClick={() => changeState(false)}
            >
              {" "}
              <img className="close-btn" src={close} />{" "}
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
