import { CurrencyFormat, DateTimeFormat } from "@utils";

const types = ["Pendiente", "Atendida: Pago realizado", "Atendida: No pagó"];
//edición de color de celdas
function statusFormatter(status) {
  if (status === 1) {
    return (
      <span>
        <strong style={{ color: "gray" }}>Pendiente</strong>
      </span>
    );
  } else if (status === 2) {
    return (
      <span>
        <strong style={{ color: "green" }}>
          {" "}
          Pago completo
        </strong>
      </span>
    );
  } else if (status === 3) {
    return (
      <span>
        <strong style={{ color: "#F5CA1B" }}>
          {" "}
          Pago parcial
        </strong>
      </span>
    );}else
    return (
      <span>
        <strong style={{ color: "red" }}>No pagó</strong>
      </span>
    );
}

export const sorts = [{dataField: "sortOrder", order: "asc" }];
export const columns = [
  { selector: (row) =>  row.sortOrder, name: "order", sortable: true, wrap: true, omit: true},
  { selector: row => row.branchName, name: "Nombre de tienda", minWidth: "30%", sortable: false, wrap: true},
  { selector: (row) => CurrencyFormat(row.estimate), name: "Monto", minWidth: "30%", sortable: false, wrap: true},
  { selector: (row) =>  statusFormatter(row.status), name: "Estatus", minWidth: "30%", sortable: false, wrap: true},
]