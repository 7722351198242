import React from "react";
import { useQuery } from "react-query";
import { Header } from "@components";
import { fetchFailedTx } from "@services/tx";
import { columns } from "@tables/failedTx";
import DataTable from "react-data-table-component";
import { JsonViewer } from "@textea/json-viewer";
import { COLORS } from "../../styles/colors";
import { pageOptions } from "@utils";
const FailedTX = ({ ...props }) => {
  const { data: failedTx, refetch } = useQuery("failedTx", fetchFailedTx);
  const handleUpdate = () => {
    refetch();
  };

  const expandRow = (row) => {
    console.log(row.data);
    return (
      <div className="expandTable">
        <p>
          <JsonViewer value={row.data.request} name="Request" />
        </p>
        <p>
          <JsonViewer value={row.data.response} name="Response" />
        </p>
      </div>
    );
  };

  const customStyles = {
    headCells: {
      style: {
        color: COLORS.white,
        fontWeight: 600,
        paddingRight: 0,
        fontSize: 14,
        backgroundColor: " #6533ff !important",
      },
    },
    table: {
      style: {
        maxHeight: "400px !important",
        overflow: "auto",
      },
    },
    headRow: {
      style: {
        zIndex: "0 !important",
      },
    },
    subHeader: {
      style: {
        padding: "0 10",
        marginBottom: 30,
        justifyContent: "space-between",
      },
    },
    rows: {
      style: {
        fontSize: 14,
      },
    },
  };
  return (
    <>
      <Header />
      <div className="container containerStyle">
        <h1 className="viewTitle ">Tx Fallidas</h1>
        {failedTx && (
          <div className="d-flex flex-column m-2 mx-12">
            <DataTable
              columns={columns}
              data={failedTx}
              defaultSortFieldId={1}
              expandableRows
              expandOnRowClicked={true}
              expandableRowsComponent={expandRow}
              expandableRowsHideExpander={true}
              pagination
              paginationComponentOptions={pageOptions}
              bordered={true}
              customStyles={customStyles}
              fixedHeader
              keyField="upc"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FailedTX;
