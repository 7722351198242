import { login } from '@services/auth'
import * as types from '../types';

export const authError = message => {
  return {
    type: types.AUTH_ERROR,
    payload: message,
  };
};

export const setIsValidToken = isValid => {
  console.log(isValid);
  return {
    type: types.SET_VALID_TOKEN,
    payload: isValid,
  };
};

export const setUserData = userData => {
  return {
    type: types.SET_USER_DATA,
    payload: userData,
  };
};

export const setUserDisplayData = userData => {
  return {
    type: types.SET_USER_DISPLAY_DATA,
    payload: userData,
  };
};

export const signIn = (username, password) => async dispatch => {
  dispatch({ type: types.LOADING });
  try {
    const response = await login(username, password);
    dispatch(setUserData(response));
    dispatch(setIsValidToken(true));
    dispatch({ type: types.LOADING });
  } catch (err) {
    dispatch(authError('Tu número de teléfono o contraseña son incorrectos'));
    dispatch({ type: types.LOADING });
  }
};

export const signOut = () => async dispatch => {
  dispatch({ type: types.LOADING });
  try {
    dispatch(setUserData({}));
    dispatch(setIsValidToken(false));
    dispatch({ type: types.LOADING });
  } catch (err) {
    dispatch({ type: types.LOADING });
  }
};