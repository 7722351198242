import axios from '@utils/services'

export const fetchPickups = async (values) => {
    try {
      let pickupNumber = '';
      if(values !== undefined && values.user.role == 'Pickup')
        pickupNumber = values.branchID;
      const response = await axios.post('/api/Collect/PickupBalance',{ "PickupNumber": pickupNumber});
      return response.data.data;
    } catch (err) {
      console.log(err.response);
    }
  };
export const fetchPickupBalance = async (values) => {
    try {
      const response = await axios.post('/api/Collect/PickupBalance',{ "PickupNumber": ""});
      return response.data.data.map((i,v)=>{return {"pickupNumber" : i.pickupNumber, "inTransit" : i.inTransit}});
    } catch (err) {
      console.log(err.response);
    }
};
export const registredDeposit = async (values) => {
  console.log(values);
  try {
    const response = await axios.post('/api/Collect/ReportPickupDeposit',values, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
}

export const doCollect = async (values) => {
  try{
    
    const response = await axios.post('api/Collect/Collect',values);
    return response.data;
  } catch(error) {
    console.log(error.response)
  }
}

export const nonPayment = async (values) => {
  try{
    const response = await axios.post('api/Collect/NonPayment',values);
    console.log(response);
    return response.data;
  }
  catch(error){
    console.log(error.response)
  }
}

export default { fetchPickups, registredDeposit,doCollect,nonPayment }